import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import { HeaderElementDirective } from '@core/shared/util';
import { ListHeaderComponent, RootModuleNavComponent } from '@core/ui';
import { ApiKey, ApiKeyItem, ApiKeysFacade } from '@mp/kernel/api-keys/data-access';

import { ApiKeyTableComponent } from '../api-key-table/api-key-table.component';

@Component({
  selector: 'mpk-api-key-overview',
  standalone: true,
  templateUrl: './api-key-overview.component.html',
  styleUrl: './api-key-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    DecimalPipe,
    LetDirective,

    MatIconModule,
    MatButtonModule,

    ApiKeyTableComponent,
    ListHeaderComponent,
    HeaderElementDirective,
    RootModuleNavComponent,
  ],
})
export class ApiKeyOverviewComponent implements OnInit {
  readonly keys$: Observable<ApiKeyItem[]> = this.facade.apiKeys$;

  constructor(private readonly facade: ApiKeysFacade) {}

  ngOnInit(): void {
    this.loadAll();
  }

  loadAll(): void {
    this.facade.getAll();
  }

  onEdit(key: ApiKeyItem): void {
    key.editMode = true;
  }

  onDelete(key: ApiKey): void {
    this.facade.deleteApiKey(key);
  }

  onCreate(): void {
    this.facade.createApiKey();
  }

  onSave(key: ApiKey): void {
    if (key.apiKey) {
      this.facade.updateApiKey(key);
    } else {
      this.facade.saveCreateApiKey(key);
    }
  }
}
