<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="API-Keys" />
</ng-container>

<div class="mp-page">
  <div class="mp-page__main">
    <div class="mp-page__content mp-page--padding">
      <ng-container *ngrxLet="keys$ as keys">
        <div class="list-header">
          <mp-list-header headerPlaceholder="API-Keys" [totalItemsQuantity]="keys.length">
            <mat-icon class="list-header__icon material-icons-outlined" list-header-prefix>key</mat-icon>
          </mp-list-header>

          <button class="list-header__actions" mat-raised-button color="accent" (click)="onCreate()">
            <mat-icon>add</mat-icon>
            Neuer API-Key
          </button>
        </div>

        <mpk-api-key-table [keys]="keys ?? []" (saveClicked)="onSave($event)" (deleteClicked)="onDelete($event)" />
      </ng-container>
    </div>
  </div>
</div>
