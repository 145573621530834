import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'mpkIsValidDate', standalone: true })
export class IsValidDatePipe implements PipeTransform {
  transform(from: string | Date, to: string | Date): boolean {
    const today = DateTime.now().startOf('day');

    if (from) {
      const fromDate = from instanceof Date ? DateTime.fromJSDate(from) : DateTime.fromISO(from);
      // from is in the future
      if (!fromDate.isValid || fromDate.startOf('day') > today) {
        return false;
      }
    }

    if (to) {
      const toDate = to instanceof Date ? DateTime.fromJSDate(to) : DateTime.fromISO(to);
      // to is in the past
      if (!toDate.isValid || toDate.startOf('day') < today) {
        return false;
      }
    }

    return true;
  }
}
