import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the api keys
 */
enum ApiKeysPermissionsEnum {
  None = 0,

  /**
   * API Keys can be viewed and managed
   */
  ManageApiKeys = 1,
}

export const ApiKeysPermissions = permissions(ApiKeysPermissionsEnum, {
  featureId: 'ApiKeys',
});
